import handleNavigationClick from '@utils/navigation/handleNavigationClick';
import classNames from 'classnames';
import React, { useContext } from 'react';
import { BEGIN_STYLE_QUIZ_LINK } from '@constants/styleQuiz';
import { useRouter } from 'next/router';
import { LocalCartContext } from '@context/LocalCart/LocalCartContext';
import ShopNavigationContext from '@components/shared/shop/ShopNavigationMenu/ShopNavigationContext';
import Button from '../../components/Button/Button';
import { loggedOutLinks } from '../../components/NavLinks/links';
import NavLinks from '../../components/NavLinks/DesktopView/NavLinks';
import styles from './LoggedOutHeader.module.scss';
import Cart from '../../../Cart/Cart';

interface ILoggedOutHeaderProps {
  className?: string;
  showGetStarted?: boolean;
  hideNavLinks?: boolean;
  afterLoginRedirectUrl?: string;
  cities?: string[];
  isTransparent?: boolean;
}

const LoggedOutHeader = ({
  className,
  showGetStarted,
  hideNavLinks,
  afterLoginRedirectUrl,
  isTransparent = true
}: ILoggedOutHeaderProps) => {
  const router = useRouter();
  const isAtHomePage = router.asPath?.includes('/in-person') ?? false;
  const getStartedAtHomeLink = '/in-person#get-started';
  const { totalCartItems } = useContext(LocalCartContext);
  const { shopNavigationData, hideShopSubMenu } = useContext(ShopNavigationContext);

  return (
    <nav className={classNames(
      className,
      styles.LoggedOutHeader,
      { [styles.Opaque]: !isTransparent }
    )}
    >
      <NavLinks
        navLinks={loggedOutLinks(shopNavigationData, hideShopSubMenu)}
        hideNavLinks={hideNavLinks}
      />
      <div className={styles.ButtonsContainer}>
        <Button
          label="Login"
          size="small"
          type="secondary"
          url={`/login${afterLoginRedirectUrl ? `?redirect=${afterLoginRedirectUrl}` : ''}`}
          handleOnClick={(title: string) => handleNavigationClick(title)}
          desktopOnly
        />
        {showGetStarted && (
          <Button
            label="GET STARTED"
            size="small"
            type="primary"
            url={isAtHomePage ? getStartedAtHomeLink : BEGIN_STYLE_QUIZ_LINK}
            handleOnClick={(title: string) => handleNavigationClick(title)}
            desktopOnly
          />
        )}

        {totalCartItems > 0 && (
        <Cart
          cartItemCount={totalCartItems}
          onClickNavItem={(title: string) => handleNavigationClick(title)}
        />
        )}
      </div>
    </nav>
  );
};

export default LoggedOutHeader;
